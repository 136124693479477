@import '../../global/styles/lib';

.single-post {
	article {
		padding-top: 2rem;
	} // article
	
	.byline {
		border: 2px solid $gray-light;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1rem;
		width: 90%;
		max-width: 20rem;
		padding: 0.75rem;
		color: $red;
		font-weight: 500;
		
		&__category {
			font-weight: 600;
			text-transform: uppercase;
			a {
				color: $red;
			}
		} // &__category
		
		&__sep {
			margin: 0 0.5rem;
		} // &__sep
		
	} // .byline
	
	.article-header {
		text-align: center;
		margin-bottom: 2rem;
		.excerpt {
			font-weight: 500;
			color: $gray-medium;
			margin-top: 1rem;
		} // .excerpt
		
		.tags {
			margin-top: 1rem;
			text-align: center;
			width: 100%;
			background: $gray-light;
			padding: 0.25rem 0;
			&__sep {
				margin: 0 0.25rem;
			} // &__sep
			
			&__item {
				a {
					color: unset;
				}
			} // &__item
		} // .tags
		
		.excerpt {
			color: $gray-medium;
		} // .excerpt
		
	} // .article-header
	
} // .single-post